import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'

import { Country, Currency, Widget } from '../../../generated/graphql'
import { ComponentWidgets as QUERY } from '../../../graphql/Query/ComponentWidgets/ComponentWidgets.graphql'
import { ClientBehaviours } from '../../utils'

export interface ComponentWidgetsData {
  componentWidgets: Widget[]
}

export interface ComponentWidgetsVariables {
  name: string
  currency: Currency
  shippingDestination: Country
  subscriptionsEnabled?: boolean
  subscriptionContractsEnabled?: boolean
  vipPriceEnabled?: boolean
  productContentKeys?: string[]
}

export enum ComponentName {
  BASKET = 'basket',
  GLOBAL = 'global',
  WISHLIST = 'wishlist',
  ACCOUNT = 'account',
  SEARCH = 'search',
  NORESULTS = 'no-results',
  STORE_LOCATOR = 'store-locator',
  PAGE_UNAVAILABLE = 'page-unavailable',
  PERSONALISE = 'personalise-your-box',
}

export const ComponentWidgets = (
  client: ApolloClient<NormalizedCacheObject>,
  { behaviours }: { behaviours: ClientBehaviours },
) => async (_: any, args) => {
  if (behaviours.override) {
    return await import('../../data/ComponentWidgets').then((mod) => {
      return mod.MOCK_COMPONENT_WIDGETS
    })
  }

  const { data } = await client.query<ComponentWidgetsData>({
    query: QUERY,
    variables: args,
  })

  return data?.componentWidgets
}
